<template>
    <v-card class="ma-0 pa-0" flat style="background: transparent !important">
        <v-card-text class="ma-0 pa-0">
            <!-- <div style="background: white !important; border-radius: 50%; padding: 0px; margin: 0px"> -->
            <v-chart class="chart ma-0 pa-0" :key="updateKey" :option="option"
                style="height: 80px; width: 80px; padding: 0px; margin: 0px;" />
            <!-- </div> -->
        </v-card-text>
    </v-card>
</template>
<script>
import VChart, { THEME_KEY } from "vue-echarts";
import { use } from "echarts/core";
import { TooltipComponent, LegendComponent } from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

use([
    TooltipComponent,
    LegendComponent,
    PieChart,
    CanvasRenderer,
    LabelLayout
]);

export default {
    props: ['data', 'total', 'updateKey'],
    components: {
        VChart
    },
    provide: {
        // [THEME_KEY]: "dark"
    },
    data() {
        return {
            option: {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    show: false
                },
                series: [
                    {
                        name: 'Access From',
                        tooltip: {
                            show: false
                        },
                        type: 'pie',
                        radius: [0, '50%'],
                        color: ['#fff'],
                        silent: true,

                        label: {
                            position: 'center',
                            fontSize: 14
                        },
                        labelLine: {
                            show: false
                        },
                        data: [
                            { value: 100, name: this.total }
                        ]
                    },
                    {
                        type: 'pie',
                        radius: ['50%', '70%'],
                        avoidLabelOverlap: false,
                        color: ['#4CAF50', '#03A9F4', '#F44336'],

                        //   label: {
                        //     show: true,
                        //     position: 'center',
                        //     backgroundColor: '#fff',
                        //     padding: 5,
                        //     borderRadius: '50',
                        //     formatter: () => {
                        //       return 'TEST'; // Use sum variable here
                        //     },
                        //   },
                        labelLine: {
                            show: false
                        },
                        label: {
                            position: 'center',
                            fontSize: 14,
                            show: false
                        },
                        data: this.data
                        //   [
                        //     { value: 1048, name: 'On Hand' },
                        //     { value: 735, name: 'Allocated' },
                        //     { value: 580, name: 'Damaged' }
                        //   ]
                    }
                ]
            }
        }
    }
};
</script>
  
<style scoped>
.chart {
    height: 50px;
    ;
    width: 50px;
}
</style>